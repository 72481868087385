<template>
  <!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2807 1604"
    style="enable-background: new 0 0 2807 1604"
    xml:space="preserve"
  >
    <g>
      <g>
        <defs>
          <rect id="SVGID_1_" width="2806.243" height="1603.567" />
        </defs>
        <use xlink:href="#SVGID_1_" style="overflow: visible; fill: #ffffff" />
        <clipPath id="SVGID_00000055699862446057647970000009363149873061073574_">
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>
        <g
          style="
            clip-path: url(#SVGID_00000055699862446057647970000009363149873061073574_);
          "
        >
          <g>
            <linearGradient
              id="SVGID_00000044155014242923184070000001842483523476608955_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.5974"
              x2="1406.5049"
              y2="-1190.0009"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000044155014242923184070000001842483523476608955_);
              "
              d="M1899.614,1085.113H913.395V514.818
					h986.219V1085.113z M915.625,1082.884h981.76V517.047h-981.76V1082.884z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000049919822969798126590000015570897751168811911_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.7314"
              x2="1406.5049"
              y2="-1190.1439"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="1031.241"
              style="
                fill: url(#SVGID_00000049919822969798126590000015570897751168811911_);
              "
              width="983.99"
              height="2.23"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000084501738454337701090000010772262234707622057_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6438"
              x2="1406.5049"
              y2="-1190.0431"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="979.599"
              style="
                fill: url(#SVGID_00000084501738454337701090000010772262234707622057_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000051353710163706589090000000042416929266189245_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.645"
              x2="1406.5049"
              y2="-1190.0419"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="927.957"
              style="
                fill: url(#SVGID_00000051353710163706589090000000042416929266189245_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000150795156314271845180000009949782405402581888_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.646"
              x2="1406.5049"
              y2="-1190.0408"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="876.315"
              style="
                fill: url(#SVGID_00000150795156314271845180000009949782405402581888_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000043447076884402706860000003514907872800928925_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6665"
              x2="1406.5049"
              y2="-1190.0575"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="824.673"
              style="
                fill: url(#SVGID_00000043447076884402706860000003514907872800928925_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000162316288614033088010000001088617319222632320_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6484"
              x2="1406.5049"
              y2="-1190.0385"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="773.031"
              style="
                fill: url(#SVGID_00000162316288614033088010000001088617319222632320_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000070800225657671404620000004643766229534205328_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6724"
              x2="1406.5049"
              y2="-1190.0566"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="721.386"
              style="
                fill: url(#SVGID_00000070800225657671404620000004643766229534205328_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000140002780433324984650000013463051106570499228_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6506"
              x2="1406.5049"
              y2="-1190.0363"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="669.744"
              style="
                fill: url(#SVGID_00000140002780433324984650000013463051106570499228_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000145752839541472744590000004070068502573577907_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6516"
              x2="1406.5049"
              y2="-1190.0352"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="618.102"
              style="
                fill: url(#SVGID_00000145752839541472744590000004070068502573577907_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000183223219020763319800000001182716887491706277_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="3012.6528"
              x2="1406.5049"
              y2="-1190.0342"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="914.51"
              y="566.46"
              style="
                fill: url(#SVGID_00000183223219020763319800000001182716887491706277_);
              "
              width="983.99"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000070804982921868954620000018350378430733663667_"
              gradientUnits="userSpaceOnUse"
              x1="1836.9995"
              y1="3012.5994"
              x2="1836.9995"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1835.885"
              y="515.932"
              style="
                fill: url(#SVGID_00000070804982921868954620000018350378430733663667_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000072995685450394215480000009494669753257856923_"
              gradientUnits="userSpaceOnUse"
              x1="1775.5022"
              y1="3012.5994"
              x2="1775.5022"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1774.387"
              y="515.932"
              style="
                fill: url(#SVGID_00000072995685450394215480000009494669753257856923_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000001656062754373079920000015343974985672050092_"
              gradientUnits="userSpaceOnUse"
              x1="1714.002"
              y1="3012.5994"
              x2="1714.002"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1712.887"
              y="515.932"
              style="
                fill: url(#SVGID_00000001656062754373079920000015343974985672050092_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000103255612929551041770000011838709605170631862_"
              gradientUnits="userSpaceOnUse"
              x1="1652.502"
              y1="3012.5994"
              x2="1652.502"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1651.387"
              y="515.932"
              style="
                fill: url(#SVGID_00000103255612929551041770000011838709605170631862_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000058575983267810978200000013656299013476239747_"
              gradientUnits="userSpaceOnUse"
              x1="1591.0042"
              y1="3012.5994"
              x2="1591.0042"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1589.89"
              y="515.932"
              style="
                fill: url(#SVGID_00000058575983267810978200000013656299013476239747_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000103978927131257632240000000530129082263594644_"
              gradientUnits="userSpaceOnUse"
              x1="1529.5038"
              y1="3012.5994"
              x2="1529.5038"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1528.389"
              y="515.932"
              style="
                fill: url(#SVGID_00000103978927131257632240000000530129082263594644_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000037686354622660963950000001553992859919428748_"
              gradientUnits="userSpaceOnUse"
              x1="1468.0035"
              y1="3012.5994"
              x2="1468.0035"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1466.889"
              y="515.932"
              style="
                fill: url(#SVGID_00000037686354622660963950000001553992859919428748_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000029007598191612869420000003403576331392798868_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5063"
              y1="3012.5994"
              x2="1406.5063"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1405.392"
              y="515.932"
              style="
                fill: url(#SVGID_00000029007598191612869420000003403576331392798868_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000156576315443095059070000004719538133448298116_"
              gradientUnits="userSpaceOnUse"
              x1="1345.0061"
              y1="3012.5994"
              x2="1345.0061"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1343.891"
              y="515.932"
              style="
                fill: url(#SVGID_00000156576315443095059070000004719538133448298116_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000023987276395483213950000010694840675820434058_"
              gradientUnits="userSpaceOnUse"
              x1="1283.506"
              y1="3012.5994"
              x2="1283.506"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1282.391"
              y="515.932"
              style="
                fill: url(#SVGID_00000023987276395483213950000010694840675820434058_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000040573885864971753930000007678057247122412980_"
              gradientUnits="userSpaceOnUse"
              x1="1222.0085"
              y1="3012.5994"
              x2="1222.0085"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1220.894"
              y="515.932"
              style="
                fill: url(#SVGID_00000040573885864971753930000007678057247122412980_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000012441279901477101750000009885207390270819458_"
              gradientUnits="userSpaceOnUse"
              x1="1160.5084"
              y1="3012.5994"
              x2="1160.5084"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1159.394"
              y="515.932"
              style="
                fill: url(#SVGID_00000012441279901477101750000009885207390270819458_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000041983093087081703110000016258699102196672408_"
              gradientUnits="userSpaceOnUse"
              x1="1099.0081"
              y1="3012.5994"
              x2="1099.0081"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1097.894"
              y="515.932"
              style="
                fill: url(#SVGID_00000041983093087081703110000016258699102196672408_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000150078527568593384780000004308910135315960255_"
              gradientUnits="userSpaceOnUse"
              x1="1037.5104"
              y1="3012.5994"
              x2="1037.5104"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1036.396"
              y="515.932"
              style="
                fill: url(#SVGID_00000150078527568593384780000004308910135315960255_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000093863792689672089350000010088599654731806128_"
              gradientUnits="userSpaceOnUse"
              x1="976.0102"
              y1="3012.5994"
              x2="976.0102"
              y2="-1189.9984"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="974.895"
              y="515.932"
              style="
                fill: url(#SVGID_00000093863792689672089350000010088599654731806128_);
              "
              width="2.229"
              height="568.066"
            />
          </g>
        </g>
        <g
          style="
            clip-path: url(#SVGID_00000055699862446057647970000009363149873061073574_);
          "
        >
          <g>
            <linearGradient
              id="SVGID_00000147927008143190016440000008271321332164564412_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.0842"
              x2="1406.5049"
              y2="883.4537"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000147927008143190016440000008271321332164564412_);
              "
              d="M1898.791,517.047H914.219
					l-0.253-0.144L-101.509-53.251h3016.028l-3.711,2.085L1898.791,517.047z M914.801,514.818h983.408L2906.004-51.022H-92.994
					L914.801,514.818z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000078002931655350202930000014356680734968929421_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5051"
              y1="-27.0953"
              x2="1406.5051"
              y2="883.4608"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="882.456"
              y="496.82"
              style="
                fill: url(#SVGID_00000078002931655350202930000014356680734968929421_);
              "
              width="1048.097"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000120557461580147048360000000217571070431375283_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.0947"
              x2="1406.5049"
              y2="883.4614"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="845.936"
              y="476.315"
              style="
                fill: url(#SVGID_00000120557461580147048360000000217571070431375283_);
              "
              width="1121.138"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000049212304725628230090000013610534292548587189_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.0943"
              x2="1406.5049"
              y2="883.4617"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="803.942"
              y="452.738"
              style="
                fill: url(#SVGID_00000049212304725628230090000013610534292548587189_);
              "
              width="1205.125"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000029008588206931463660000004805871878631347097_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5062"
              y1="-27.0938"
              x2="1406.5062"
              y2="883.4623"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="755.149"
              y="425.342"
              style="
                fill: url(#SVGID_00000029008588206931463660000004805871878631347097_);
              "
              width="1302.714"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000082342117959500845540000017544259487179551893_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.093"
              x2="1406.5049"
              y2="883.4631"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="697.754"
              y="393.117"
              style="
                fill: url(#SVGID_00000082342117959500845540000017544259487179551893_);
              "
              width="1417.501"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000101076619723791263700000015729843412963619208_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.0923"
              x2="1406.5049"
              y2="883.4637"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="629.27"
              y="354.666"
              style="
                fill: url(#SVGID_00000101076619723791263700000015729843412963619208_);
              "
              width="1554.47"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000130620459277902323700000009618432745992640910_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.1064"
              x2="1406.5049"
              y2="883.4905"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="546.134"
              y="307.988"
              style="
                fill: url(#SVGID_00000130620459277902323700000009618432745992640910_);
              "
              width="1720.742"
              height="2.23"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000045592991719198604980000003471690240169592747_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="-27.0902"
              x2="1406.5049"
              y2="883.4659"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="443.083"
              y="250.128"
              style="
                fill: url(#SVGID_00000045592991719198604980000003471690240169592747_);
              "
              width="1926.843"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000078740936905372361600000001209668131477583537_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5062"
              y1="-27.0884"
              x2="1406.5062"
              y2="883.4677"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="311.99"
              y="176.522"
              style="
                fill: url(#SVGID_00000078740936905372361600000001209668131477583537_);
              "
              width="2189.033"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000038390270451734259570000011772132975039557800_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5062"
              y1="-27.0862"
              x2="1406.5062"
              y2="883.4699"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="139.598"
              y="79.732"
              style="
                fill: url(#SVGID_00000038390270451734259570000011772132975039557800_);
              "
              width="2533.816"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000049920062242230505030000008190737411854862003_"
              gradientUnits="userSpaceOnUse"
              x1="2279.6458"
              y1="-27.0836"
              x2="2279.6458"
              y2="883.4542"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000049920062242230505030000008190737411854862003_);
              "
              points="1837.6,516.87 
					1836.399,514.995 2721.691,-53.074 2722.893,-51.198 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000150089161011940988960000010656993319295979906_"
              gradientUnits="userSpaceOnUse"
              x1="2154.9121"
              y1="-27.0837"
              x2="2154.9121"
              y2="883.4545"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000150089161011940988960000010656993319295979906_);
              "
              points="1776.168,516.824 
					1774.836,515.041 2533.656,-53.028 2534.988,-51.245 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000000927081402948667630000014404019236718148745_"
              gradientUnits="userSpaceOnUse"
              x1="2030.1785"
              y1="-27.084"
              x2="2030.1785"
              y2="883.4535"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000000927081402948667630000014404019236718148745_);
              "
              points="1714.747,516.761 
					1713.257,515.103 2345.61,-52.966 2347.1,-51.307 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000183932385413766281050000001614797187269564598_"
              gradientUnits="userSpaceOnUse"
              x1="1905.4435"
              y1="-27.084"
              x2="1905.4435"
              y2="883.4519"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000183932385413766281050000001614797187269564598_);
              "
              points="1653.334,516.672 
					1651.67,515.193 2157.553,-52.876 2159.217,-51.397 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000039819710074783049930000010379930684200371343_"
              gradientUnits="userSpaceOnUse"
              x1="1780.7096"
              y1="-27.0842"
              x2="1780.7096"
              y2="883.4526"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000039819710074783049930000010379930684200371343_);
              "
              points="1591.932,516.552 
					1590.077,515.313 1969.488,-52.754 1971.342,-51.519 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000041273445108671533330000010920177925962037414_"
              gradientUnits="userSpaceOnUse"
              x1="1655.9749"
              y1="-27.0836"
              x2="1655.9749"
              y2="883.4501"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000041273445108671533330000010920177925962037414_);
              "
              points="1530.521,516.387 
					1528.487,515.479 1781.429,-52.59 1783.463,-51.682 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000115496212030239601790000006837386633850142632_"
              gradientUnits="userSpaceOnUse"
              x1="1531.2399"
              y1="-27.0839"
              x2="1531.2399"
              y2="883.4533"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000115496212030239601790000006837386633850142632_);
              "
              points="1469.091,516.174 
					1466.916,515.691 1593.389,-52.378 1595.563,-51.894 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000105411301888298969420000018392507764046503307_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5063"
              y1="-27.0841"
              x2="1406.5063"
              y2="883.4505"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1405.392"
              y="-52.137"
              style="
                fill: url(#SVGID_00000105411301888298969420000018392507764046503307_);
              "
              width="2.229"
              height="568.069"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000064346638544702354160000004520715130932216193_"
              gradientUnits="userSpaceOnUse"
              x1="1281.7712"
              y1="-27.0839"
              x2="1281.7712"
              y2="883.4533"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000064346638544702354160000004520715130932216193_);
              "
              points="1343.919,516.174 
					1217.449,-51.894 1219.624,-52.378 1346.094,515.691 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000003067684099525034770000018335191853209709705_"
              gradientUnits="userSpaceOnUse"
              x1="1157.0363"
              y1="-27.0836"
              x2="1157.0363"
              y2="883.4501"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000003067684099525034770000018335191853209709705_);
              "
              points="1282.489,516.387 
					1029.55,-51.682 1031.584,-52.59 1284.523,515.479 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000176756952971357146880000016165888143868539544_"
              gradientUnits="userSpaceOnUse"
              x1="1032.303"
              y1="-27.0842"
              x2="1032.303"
              y2="883.4526"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000176756952971357146880000016165888143868539544_);
              "
              points="1221.082,516.552 
					841.67,-51.519 843.524,-52.754 1222.936,515.313 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000101072850375883020360000008462688204008149401_"
              gradientUnits="userSpaceOnUse"
              x1="907.5679"
              y1="-27.084"
              x2="907.5679"
              y2="883.4519"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000101072850375883020360000008462688204008149401_);
              "
              points="1159.676,516.672 
					653.796,-51.397 655.459,-52.876 1161.34,515.193 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000155130135174970055870000003082204865142789037_"
              gradientUnits="userSpaceOnUse"
              x1="782.8326"
              y1="-27.084"
              x2="782.8326"
              y2="883.4535"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000155130135174970055870000003082204865142789037_);
              "
              points="1098.263,516.761 
					465.913,-51.307 467.403,-52.966 1099.753,515.103 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000026133423926039688510000006175822360082261155_"
              gradientUnits="userSpaceOnUse"
              x1="658.0994"
              y1="-27.0837"
              x2="658.0994"
              y2="883.4545"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000026133423926039688510000006175822360082261155_);
              "
              points="1036.844,516.824 
					278.022,-51.245 279.354,-53.028 1038.177,515.041 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000096042972244346857360000015731801807129859734_"
              gradientUnits="userSpaceOnUse"
              x1="533.3644"
              y1="-27.0836"
              x2="533.3644"
              y2="883.4542"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000096042972244346857360000015731801807129859734_);
              "
              points="975.409,516.87 
					90.118,-51.198 91.319,-53.074 976.611,514.995 				"
            />
          </g>
        </g>
        <g
          style="
            clip-path: url(#SVGID_00000055699862446057647970000009363149873061073574_);
          "
        >
          <g>
            <linearGradient
              id="SVGID_00000063612099445413519490000007331829236971010968_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1099"
              y1="799.9652"
              x2="1264.2314"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000063612099445413519490000007331829236971010968_);
              "
              d="M2883.604,1638.843l-1.661-0.932
					l-984.558-553.26V515.28l0.568-0.318l985.651-553.875V1638.843z M1899.614,1083.346l981.761,551.691V-35.106l-981.761,551.691
					V1083.346z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000111881564462487304930000014334678721227917742_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="1258.5593"
              x2="1264.2411"
              y2="1258.5593"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000111881564462487304930000014334678721227917742_);
              "
              points="2882.025,1485.774 
					1898.035,1033.368 1898.965,1031.345 2882.954,1483.751 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000167376867022688697290000006080935702576471947_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="1156.651"
              x2="1264.2393"
              y2="1156.651"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000167376867022688697290000006080935702576471947_);
              "
              points="2882.115,1333.637 
					1898.124,981.764 1898.875,979.665 2882.865,1331.538 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000123426919994137897220000001828102843869557397_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1106"
              y1="1054.741"
              x2="1264.2341"
              y2="1054.741"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000123426919994137897220000001828102843869557397_);
              "
              points="2882.215,1181.489 
					1898.225,930.151 1898.774,927.993 2882.764,1179.33 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000065044331211416001080000014580517457754921114_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="952.8312"
              x2="1264.2382"
              y2="952.8312"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000065044331211416001080000014580517457754921114_);
              "
              points="2882.321,1029.333 
					1898.332,878.531 1898.668,876.329 2882.658,1027.131 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000168075905928651893630000006334370612875116706_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="850.9211"
              x2="1264.2391"
              y2="850.9211"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000168075905928651893630000006334370612875116706_);
              "
              points="2882.433,877.166 
					1898.443,826.9 1898.557,824.676 2882.547,874.943 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000049186447116451950890000011137572709963892106_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="749.011"
              x2="1264.2391"
              y2="749.011"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000049186447116451950890000011137572709963892106_);
              "
              points="1898.557,775.258 
					1898.443,773.034 2882.433,722.765 2882.547,724.988 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000071541098113550109030000004533017065317350318_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="647.0999"
              x2="1264.2382"
              y2="647.0999"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000071541098113550109030000004533017065317350318_);
              "
              points="1898.668,723.602 
					1898.332,721.4 2882.321,570.598 2882.658,572.8 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000151532644027453593760000009825877482069791116_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1106"
              y1="545.1913"
              x2="1264.2341"
              y2="545.1913"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000151532644027453593760000009825877482069791116_);
              "
              points="1898.774,671.938 
					1898.225,669.78 2882.215,418.444 2882.764,420.603 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000021094075612033496490000000579752963534359464_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="443.2814"
              x2="1264.2393"
              y2="443.2814"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000021094075612033496490000000579752963534359464_);
              "
              points="1898.875,620.266 
					1898.124,618.168 2882.115,266.297 2882.865,268.395 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000119807254337895036650000000887116606681105568_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1104"
              y1="341.3713"
              x2="1264.2411"
              y2="341.3713"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000119807254337895036650000000887116606681105568_);
              "
              points="1898.965,568.586 
					1898.035,566.563 2882.025,114.157 2882.954,116.18 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000138536460146441745350000009058832591298463159_"
              gradientUnits="userSpaceOnUse"
              x1="2878.114"
              y1="799.9656"
              x2="1264.205"
              y2="799.9656"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2719.81"
              y="53.78"
              style="
                fill: url(#SVGID_00000138536460146441745350000009058832591298463159_);
              "
              width="2.229"
              height="1492.372"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000164484875693560469190000011829332249169550770_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1167"
              y1="799.9666"
              x2="1264.2078"
              y2="799.9666"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2589.865"
              y="126.801"
              style="
                fill: url(#SVGID_00000164484875693560469190000011829332249169550770_);
              "
              width="2.229"
              height="1346.33"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000077323844348516601170000002555636403873229184_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1189"
              y1="799.9655"
              x2="1264.21"
              y2="799.9655"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2483.087"
              y="186.804"
              style="
                fill: url(#SVGID_00000077323844348516601170000002555636403873229184_);
              "
              width="2.229"
              height="1226.323"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000183227632048167160780000002011126053628223138_"
              gradientUnits="userSpaceOnUse"
              x1="2878.0125"
              y1="799.9669"
              x2="1264.4655"
              y2="799.9669"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2393.786"
              y="236.986"
              style="
                fill: url(#SVGID_00000183227632048167160780000002011126053628223138_);
              "
              width="2.229"
              height="1125.962"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000160907301726940774550000005805775560061987517_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1223"
              y1="799.9652"
              x2="1264.2133"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2317.996"
              y="279.575"
              style="
                fill: url(#SVGID_00000160907301726940774550000005805775560061987517_);
              "
              width="2.229"
              height="1040.781"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000032645589231720656130000000517376593420049838_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1243"
              y1="799.9669"
              x2="1264.2152"
              y2="799.9669"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2252.866"
              y="316.174"
              style="
                fill: url(#SVGID_00000032645589231720656130000000517376593420049838_);
              "
              width="2.229"
              height="967.585"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000065038798495757396310000003753804189023847072_"
              gradientUnits="userSpaceOnUse"
              x1="2878.125"
              y1="799.9652"
              x2="1264.2161"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2196.295"
              y="347.961"
              style="
                fill: url(#SVGID_00000065038798495757396310000003753804189023847072_);
              "
              width="2.229"
              height="904.008"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000179609795214303216870000010254562121158576790_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1262"
              y1="799.9653"
              x2="1264.2173"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2146.703"
              y="375.831"
              style="
                fill: url(#SVGID_00000179609795214303216870000010254562121158576790_);
              "
              width="2.229"
              height="848.268"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000170986870132702282410000010816504659200401076_"
              gradientUnits="userSpaceOnUse"
              x1="2878.127"
              y1="799.9653"
              x2="1264.218"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2102.869"
              y="400.463"
              style="
                fill: url(#SVGID_00000170986870132702282410000010816504659200401076_);
              "
              width="2.229"
              height="799.005"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000109730005893082937030000003673247327120309391_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1279"
              y1="799.9666"
              x2="1264.219"
              y2="799.9666"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2063.847"
              y="422.392"
              style="
                fill: url(#SVGID_00000109730005893082937030000003673247327120309391_);
              "
              width="2.229"
              height="755.149"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000154415388742093889870000016469568226607005063_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1287"
              y1="799.9653"
              x2="1264.2196"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="2028.884"
              y="442.037"
              style="
                fill: url(#SVGID_00000154415388742093889870000016469568226607005063_);
              "
              width="2.229"
              height="715.856"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000001635481885830459560000010723510270318363551_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1294"
              y1="799.9653"
              x2="1264.2205"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1997.382"
              y="459.739"
              style="
                fill: url(#SVGID_00000001635481885830459560000010723510270318363551_);
              "
              width="2.229"
              height="680.453"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000136383892849374907350000016076459725531827123_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1301"
              y1="799.9653"
              x2="1264.2211"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1968.847"
              y="475.774"
              style="
                fill: url(#SVGID_00000136383892849374907350000016076459725531827123_);
              "
              width="2.229"
              height="648.383"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000096748629647542277030000010788618561978966928_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1304"
              y1="799.9653"
              x2="1264.2214"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1942.883"
              y="490.365"
              style="
                fill: url(#SVGID_00000096748629647542277030000010788618561978966928_);
              "
              width="2.229"
              height="619.2"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000039840852719901674960000013929378648598954639_"
              gradientUnits="userSpaceOnUse"
              x1="2878.1311"
              y1="799.9656"
              x2="1264.222"
              y2="799.9656"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1919.154"
              y="503.698"
              style="
                fill: url(#SVGID_00000039840852719901674960000013929378648598954639_);
              "
              width="2.229"
              height="592.534"
            />
          </g>
        </g>
        <g
          style="
            clip-path: url(#SVGID_00000055699862446057647970000009363149873061073574_);
          "
        >
          <g>
            <linearGradient
              id="SVGID_00000170266636661336604100000007145672409031653287_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.276"
              x2="1406.5049"
              y2="723.0379"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000170266636661336604100000007145672409031653287_);
              "
              d="M2914.519,1653.182H-101.509
					l3.711-2.085l1012.017-568.213h984.572l0.256,0.144L2914.519,1653.182z M-92.994,1650.952h2998.998l-1007.795-565.839H914.801
					L-92.994,1650.952z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000085944636132943950560000008290722579564607933_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5051"
              y1="1629.2874"
              x2="1406.5051"
              y2="723.0281"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="882.456"
              y="1100.882"
              style="
                fill: url(#SVGID_00000085944636132943950560000008290722579564607933_);
              "
              width="1048.097"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000044176993068989523550000004586696305949838726_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.3097"
              x2="1406.5049"
              y2="723.0097"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="845.936"
              y="1121.386"
              style="
                fill: url(#SVGID_00000044176993068989523550000004586696305949838726_);
              "
              width="1121.138"
              height="2.23"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000065049595107195628350000014193731864529897090_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.2859"
              x2="1406.5049"
              y2="723.0266"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="803.942"
              y="1144.965"
              style="
                fill: url(#SVGID_00000065049595107195628350000014193731864529897090_);
              "
              width="1205.125"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000070803585204115901650000009642406598448277891_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.163"
              x2="1406.5049"
              y2="723.1476"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="755.147"
              y="1172.363"
              style="
                fill: url(#SVGID_00000070803585204115901650000009642406598448277891_);
              "
              width="1302.717"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000026157612662267041650000008549143878230915234_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.2853"
              x2="1406.5049"
              y2="723.026"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="697.754"
              y="1204.585"
              style="
                fill: url(#SVGID_00000026157612662267041650000008549143878230915234_);
              "
              width="1417.501"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000085932496598210335860000009028537205493384111_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.2841"
              x2="1406.5049"
              y2="723.0248"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="629.27"
              y="1243.038"
              style="
                fill: url(#SVGID_00000085932496598210335860000009028537205493384111_);
              "
              width="1554.47"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000117659931687851637670000013773086372302178486_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.2832"
              x2="1406.5049"
              y2="723.0239"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="546.134"
              y="1289.716"
              style="
                fill: url(#SVGID_00000117659931687851637670000013773086372302178486_);
              "
              width="1720.742"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000147179421460778560420000006465167210269263247_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5049"
              y1="1629.2816"
              x2="1406.5049"
              y2="723.0223"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="443.083"
              y="1347.573"
              style="
                fill: url(#SVGID_00000147179421460778560420000006465167210269263247_);
              "
              width="1926.843"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000016787816278461491580000001964417154577769621_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5062"
              y1="1629.2344"
              x2="1406.5062"
              y2="723.1765"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="311.99"
              y="1421.18"
              style="
                fill: url(#SVGID_00000016787816278461491580000001964417154577769621_);
              "
              width="2189.033"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000052073458117915460600000007994884532350720918_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5062"
              y1="1629.2781"
              x2="1406.5062"
              y2="723.0187"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="139.598"
              y="1517.972"
              style="
                fill: url(#SVGID_00000052073458117915460600000007994884532350720918_);
              "
              width="2533.816"
              height="2.229"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000045616590837374282210000016166795424686294681_"
              gradientUnits="userSpaceOnUse"
              x1="533.3644"
              y1="1629.2761"
              x2="533.3644"
              y2="723.0358"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000045616590837374282210000016166795424686294681_);
              "
              points="91.319,1653.005 
					90.118,1651.129 975.409,1083.061 976.611,1084.936 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000060007665583676473820000007942200581905490846_"
              gradientUnits="userSpaceOnUse"
              x1="658.0994"
              y1="1629.2759"
              x2="658.0994"
              y2="723.0341"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000060007665583676473820000007942200581905490846_);
              "
              points="279.354,1652.959 
					278.022,1651.176 1036.844,1083.107 1038.177,1084.89 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000015333232335874818650000017838850757022267327_"
              gradientUnits="userSpaceOnUse"
              x1="782.8326"
              y1="1629.2762"
              x2="782.8326"
              y2="723.0337"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000015333232335874818650000017838850757022267327_);
              "
              points="467.403,1652.897 
					465.913,1651.238 1098.263,1083.169 1099.753,1084.828 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000093157859147839858300000015619081238571115155_"
              gradientUnits="userSpaceOnUse"
              x1="907.5679"
              y1="1629.2761"
              x2="907.5679"
              y2="723.0338"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000093157859147839858300000015619081238571115155_);
              "
              points="655.459,1652.807 
					653.796,1651.328 1159.676,1083.259 1161.34,1084.738 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000138571204709874805120000009744532469263245956_"
              gradientUnits="userSpaceOnUse"
              x1="1032.303"
              y1="1629.2758"
              x2="1032.303"
              y2="723.0374"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000138571204709874805120000009744532469263245956_);
              "
              points="843.524,1652.684 
					841.67,1651.45 1221.082,1083.379 1222.936,1084.619 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000099641324937292674900000018085985745169329059_"
              gradientUnits="userSpaceOnUse"
              x1="1157.0363"
              y1="1629.2758"
              x2="1157.0363"
              y2="723.0378"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000099641324937292674900000018085985745169329059_);
              "
              points="1031.584,1652.521 
					1029.55,1651.613 1282.489,1083.544 1284.523,1084.453 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000084505307043386168240000011827415040694420649_"
              gradientUnits="userSpaceOnUse"
              x1="1281.7712"
              y1="1629.276"
              x2="1281.7712"
              y2="723.0373"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000084505307043386168240000011827415040694420649_);
              "
              points="1219.624,1652.309 
					1217.449,1651.825 1343.919,1083.757 1346.094,1084.24 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000111162689567638037540000010489261879076409010_"
              gradientUnits="userSpaceOnUse"
              x1="1406.5063"
              y1="1629.2754"
              x2="1406.5063"
              y2="723.0355"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="1405.392"
              y="1083.998"
              style="
                fill: url(#SVGID_00000111162689567638037540000010489261879076409010_);
              "
              width="2.229"
              height="568.069"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000065786953697280922310000012475282390824715412_"
              gradientUnits="userSpaceOnUse"
              x1="1531.2399"
              y1="1629.276"
              x2="1531.2399"
              y2="723.0373"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000065786953697280922310000012475282390824715412_);
              "
              points="1593.389,1652.309 
					1466.916,1084.24 1469.091,1083.757 1595.563,1651.825 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000090262443143340483820000012942926508629696150_"
              gradientUnits="userSpaceOnUse"
              x1="1655.9749"
              y1="1629.2758"
              x2="1655.9749"
              y2="723.0378"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000090262443143340483820000012942926508629696150_);
              "
              points="1781.429,1652.521 
					1528.487,1084.453 1530.521,1083.544 1783.463,1651.613 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000152955451278062224820000009794048881821243013_"
              gradientUnits="userSpaceOnUse"
              x1="1780.7096"
              y1="1629.2758"
              x2="1780.7096"
              y2="723.0374"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000152955451278062224820000009794048881821243013_);
              "
              points="1969.488,1652.684 
					1590.077,1084.619 1591.932,1083.379 1971.342,1651.45 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000158741223310035587720000009471218463601585579_"
              gradientUnits="userSpaceOnUse"
              x1="1905.4421"
              y1="1629.2761"
              x2="1905.4421"
              y2="723.0338"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000158741223310035587720000009471218463601585579_);
              "
              points="2157.551,1652.807 
					1651.67,1084.738 1653.334,1083.259 2159.214,1651.328 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000046315743890411585190000009974106125025022608_"
              gradientUnits="userSpaceOnUse"
              x1="2030.177"
              y1="1629.2762"
              x2="2030.177"
              y2="723.0337"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000046315743890411585190000009974106125025022608_);
              "
              points="2345.607,1652.897 
					1713.257,1084.828 1714.747,1083.169 2347.097,1651.238 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000033348436612759419070000007543732207973271700_"
              gradientUnits="userSpaceOnUse"
              x1="2154.9121"
              y1="1629.2759"
              x2="2154.9121"
              y2="723.0341"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000033348436612759419070000007543732207973271700_);
              "
              points="2533.656,1652.959 
					1774.836,1084.89 1776.168,1083.107 2534.988,1651.176 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000101824723651451606340000014875373333927516601_"
              gradientUnits="userSpaceOnUse"
              x1="2279.6458"
              y1="1629.2761"
              x2="2279.6458"
              y2="723.0358"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000101824723651451606340000014875373333927516601_);
              "
              points="2721.691,1653.005 
					1836.399,1084.936 1837.6,1083.061 2722.893,1651.129 				"
            />
          </g>
        </g>
        <g
          style="
            clip-path: url(#SVGID_00000055699862446057647970000009363149873061073574_);
          "
        >
          <g>
            <linearGradient
              id="SVGID_00000181058026999157081650000015451328038158426268_"
              gradientUnits="userSpaceOnUse"
              x1="10.8266"
              y1="799.9652"
              x2="1519.1458"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000181058026999157081650000015451328038158426268_);
              "
              d="M-70.591,1638.843V-38.913l1.661,0.933
					l984.555,553.26v569.371l-0.568,0.319L-70.591,1638.843z M-68.362-35.106v1670.143l981.758-551.691V516.585L-68.362-35.106z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000021104143378671328050000015120769592692872342_"
              gradientUnits="userSpaceOnUse"
              x1="10.8264"
              y1="341.3713"
              x2="1519.1476"
              y2="341.3713"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000021104143378671328050000015120769592692872342_);
              "
              points="914.045,568.586 
					-69.942,116.18 -69.012,114.157 914.975,566.563 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000032647455641161015690000001055970256266908607_"
              gradientUnits="userSpaceOnUse"
              x1="10.8264"
              y1="443.2814"
              x2="1519.1487"
              y2="443.2814"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000032647455641161015690000001055970256266908607_);
              "
              points="914.135,620.266 
					-69.852,268.395 -69.102,266.297 914.885,618.168 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000133507652886169875110000017340027732956702645_"
              gradientUnits="userSpaceOnUse"
              x1="10.8261"
              y1="545.1913"
              x2="1519.1449"
              y2="545.1913"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000133507652886169875110000017340027732956702645_);
              "
              points="914.236,671.938 
					-69.751,420.603 -69.202,418.444 914.785,669.78 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000057853773680648813850000011840068591631397055_"
              gradientUnits="userSpaceOnUse"
              x1="10.826"
              y1="647.0999"
              x2="1519.1454"
              y2="647.0999"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000057853773680648813850000011840068591631397055_);
              "
              points="914.341,723.602 
					-69.646,572.8 -69.309,570.598 914.679,721.4 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000173134776307326860650000013871989726802140581_"
              gradientUnits="userSpaceOnUse"
              x1="10.8266"
              y1="749.011"
              x2="1519.1492"
              y2="749.011"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000173134776307326860650000013871989726802140581_);
              "
              points="914.453,775.258 
					-69.534,724.988 -69.42,722.765 914.567,773.034 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000013878285479850556780000007536561199070261684_"
              gradientUnits="userSpaceOnUse"
              x1="10.8266"
              y1="850.9211"
              x2="1519.1492"
              y2="850.9211"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000013878285479850556780000007536561199070261684_);
              "
              points="-69.42,877.166 
					-69.534,874.943 914.453,824.676 914.567,826.9 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000013897811149673690090000015089215893480666261_"
              gradientUnits="userSpaceOnUse"
              x1="10.826"
              y1="952.8312"
              x2="1519.1454"
              y2="952.8312"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000013897811149673690090000015089215893480666261_);
              "
              points="-69.309,1029.333 
					-69.646,1027.131 914.341,876.329 914.679,878.531 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000078760317984728497630000015559931873061883779_"
              gradientUnits="userSpaceOnUse"
              x1="10.8261"
              y1="1054.741"
              x2="1519.1449"
              y2="1054.741"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000078760317984728497630000015559931873061883779_);
              "
              points="-69.202,1181.489 
					-69.751,1179.33 914.236,927.993 914.785,930.151 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000152226981407162393910000007571849023622229639_"
              gradientUnits="userSpaceOnUse"
              x1="10.8264"
              y1="1156.651"
              x2="1519.1487"
              y2="1156.651"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000152226981407162393910000007571849023622229639_);
              "
              points="-69.102,1333.637 
					-69.852,1331.538 914.135,979.665 914.885,981.764 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000052085496062341365630000014468667077473115822_"
              gradientUnits="userSpaceOnUse"
              x1="10.8264"
              y1="1258.5593"
              x2="1519.1476"
              y2="1258.5593"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000052085496062341365630000014468667077473115822_);
              "
              points="-69.012,1485.774 
					-69.942,1483.751 914.045,1031.345 914.975,1033.368 				"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000064313399859976944570000002991515814169858219_"
              gradientUnits="userSpaceOnUse"
              x1="10.8247"
              y1="799.9656"
              x2="1519.1752"
              y2="799.9656"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="90.974"
              y="53.78"
              style="
                fill: url(#SVGID_00000064313399859976944570000002991515814169858219_);
              "
              width="2.229"
              height="1492.372"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000083782378596605935610000009190773590833414067_"
              gradientUnits="userSpaceOnUse"
              x1="10.8685"
              y1="799.9666"
              x2="1518.8844"
              y2="799.9666"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="220.918"
              y="126.801"
              style="
                fill: url(#SVGID_00000083782378596605935610000009190773590833414067_);
              "
              width="2.229"
              height="1346.33"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000040534814320755917830000007910974844946355126_"
              gradientUnits="userSpaceOnUse"
              x1="10.8195"
              y1="799.9655"
              x2="1519.1699"
              y2="799.9655"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="327.696"
              y="186.804"
              style="
                fill: url(#SVGID_00000040534814320755917830000007910974844946355126_);
              "
              width="2.229"
              height="1226.323"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000109741825979599475860000009344431163661575341_"
              gradientUnits="userSpaceOnUse"
              x1="10.8177"
              y1="799.9669"
              x2="1519.1682"
              y2="799.9669"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="416.998"
              y="236.986"
              style="
                fill: url(#SVGID_00000109741825979599475860000009344431163661575341_);
              "
              width="2.229"
              height="1125.962"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000159440427985492618150000012658336665723315636_"
              gradientUnits="userSpaceOnUse"
              x1="10.8159"
              y1="799.9652"
              x2="1519.1663"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="492.787"
              y="279.575"
              style="
                fill: url(#SVGID_00000159440427985492618150000012658336665723315636_);
              "
              width="2.229"
              height="1040.781"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000093858991576414997930000005901207232440856994_"
              gradientUnits="userSpaceOnUse"
              x1="10.8146"
              y1="799.9669"
              x2="1519.165"
              y2="799.9669"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="557.914"
              y="316.174"
              style="
                fill: url(#SVGID_00000093858991576414997930000005901207232440856994_);
              "
              width="2.229"
              height="967.585"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000004539467555914763510000007976917772813697714_"
              gradientUnits="userSpaceOnUse"
              x1="10.8131"
              y1="799.9652"
              x2="1519.1636"
              y2="799.9652"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="614.485"
              y="347.961"
              style="
                fill: url(#SVGID_00000004539467555914763510000007976917772813697714_);
              "
              width="2.229"
              height="904.008"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000027583776996298271430000004472419200595027094_"
              gradientUnits="userSpaceOnUse"
              x1="10.9588"
              y1="799.9653"
              x2="1518.9709"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="664.081"
              y="375.831"
              style="
                fill: url(#SVGID_00000027583776996298271430000004472419200595027094_);
              "
              width="2.229"
              height="848.268"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000154395060996279356520000012166765795033855166_"
              gradientUnits="userSpaceOnUse"
              x1="10.8112"
              y1="799.9653"
              x2="1519.1616"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="707.914"
              y="400.463"
              style="
                fill: url(#SVGID_00000154395060996279356520000012166765795033855166_);
              "
              width="2.229"
              height="799.005"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000054265491733009549740000015531464668014021802_"
              gradientUnits="userSpaceOnUse"
              x1="10.8104"
              y1="799.9666"
              x2="1519.1608"
              y2="799.9666"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="746.936"
              y="422.392"
              style="
                fill: url(#SVGID_00000054265491733009549740000015531464668014021802_);
              "
              width="2.229"
              height="755.149"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000114047420545758076320000018169607452074464957_"
              gradientUnits="userSpaceOnUse"
              x1="10.7749"
              y1="799.9653"
              x2="1519.193"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="781.896"
              y="442.037"
              style="
                fill: url(#SVGID_00000114047420545758076320000018169607452074464957_);
              "
              width="2.23"
              height="715.856"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000038411608269044829650000010223785842498206095_"
              gradientUnits="userSpaceOnUse"
              x1="10.8089"
              y1="799.9653"
              x2="1519.1593"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="813.401"
              y="459.739"
              style="
                fill: url(#SVGID_00000038411608269044829650000010223785842498206095_);
              "
              width="2.229"
              height="680.453"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000081649742429536588530000003826290839732356266_"
              gradientUnits="userSpaceOnUse"
              x1="10.8002"
              y1="799.9653"
              x2="1519.165"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="841.934"
              y="475.774"
              style="
                fill: url(#SVGID_00000081649742429536588530000003826290839732356266_);
              "
              width="2.229"
              height="648.383"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000181050270163824731730000007108994514372734121_"
              gradientUnits="userSpaceOnUse"
              x1="10.8078"
              y1="799.9653"
              x2="1519.1582"
              y2="799.9653"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="867.897"
              y="490.365"
              style="
                fill: url(#SVGID_00000181050270163824731730000007108994514372734121_);
              "
              width="2.229"
              height="619.2"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_00000031927621225914454170000008791845081462198439_"
              gradientUnits="userSpaceOnUse"
              x1="10.8072"
              y1="799.9656"
              x2="1519.1576"
              y2="799.9656"
            >
              <stop offset="0.1449" style="stop-color: #666666" />
              <stop offset="0.1713" style="stop-color: #757575" />
              <stop offset="0.246" style="stop-color: #9a9a9a" />
              <stop offset="0.3271" style="stop-color: #b9b9b9" />
              <stop offset="0.4149" style="stop-color: #d3d3d3" />
              <stop offset="0.5117" style="stop-color: #e6e6e6" />
              <stop offset="0.6225" style="stop-color: #f4f4f4" />
              <stop offset="0.7593" style="stop-color: #fdfdfd" />
              <stop offset="1" style="stop-color: #ffffff" />
            </linearGradient>

            <rect
              x="891.626"
              y="503.698"
              style="
                fill: url(#SVGID_00000031927621225914454170000008791845081462198439_);
              "
              width="2.229"
              height="592.534"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
